@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:700,400);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ErrorBoundary {
  padding-top: 100px;
  text-align: center;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .notes h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .Home .lander div {
    padding-top: 20px;
  }
  .Home .lander div a:first-child {
    margin-right: 20px;
  }
  
  .Home .btn {
    margin: 10px;
  }
  
  .h1 {
    text-align: center;
  }
  
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  /* Style the header */
  .header {
    grid-area: header;
    padding: 30px;
    text-align: center;
    font-size: 35px;
    margin-top: 20px;
  }
  
  /* The grid container */
  .grid-container {
    display: grid;
    grid-template-areas: 
      'header header header header header header' 
      'left left middle middle right right' 
      'footer footer footer footer footer footer';
    /* grid-column-gap: 10px; - if you want gap between the columns */
  } 
  
  .left,
  .middle,
  .right {
    padding: 10px;
    justify-content: center;
    text-align: center;
  }
  
  /* Style the left column */
  .left {
    border-radius: 20px;
    background: inherit;
    grid-area: left;
    text-align: center;
    
  }
  
  /* Style the middle column */
  .middle {
    border-radius: 20px;
    grid-area: middle;
    text-align: center;
    background: inherit;
    
  }
  
  /* Style the right column */
  .right {
    border-radius: 20px;
    grid-area: right;
    text-align: center;
    background: inherit;
  }
  
  /* Style the footer */
  .footer {
    grid-area: footer;
    padding: 10px;
    text-align: center;
  }
  
  /* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
  @media (max-width: 600px) {
    .grid-container  {
      grid-template-areas: 
        'header header header header header' 
        'left left left left left left' 
        'middle middle middle middle middle middle' 
        'right right right right right right' 
        'footer footer footer footer footer footer';
    }
  }
  
  .image1 {
    display: block;
    width: 100%;
  }
  
  .image2 {
    display: block;
    width: 100%;
  }
  
  .image3 {
    display: block;
    width: 100%;
    
  }
  .grid-container {
    background-color: inherit;
  }

  .overlay1 {
    position: absolute; 
    opacity: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    color: white;
    font-size: 16px;
    text-align: center;
    padding-left: 10px;
    margin-bottom: 40px;
    border-radius: 50px;
  }
  
  .overlay2 {
    position: absolute; 
    opacity: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    color: white;
    font-size: 16px;
    text-align: center;
    padding-left: 10px;
    margin-bottom: 40px;
  }
  
  .overlay3 {
    position: absolute; 
    opacity: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    color: white;
    font-size: 16px;
    text-align: center;
    padding-left: 10px;
    margin-bottom: 40px;
  }
  
  .left:hover .overlay1 {
    opacity: 1;
  }
  
  .middle:hover .overlay2 {
    opacity: 1;
  }
  
  .right:hover .overlay3 {
    opacity: 1;
  }
  
  
  
  .btn {
    text-align: center;
    justify-content: center;
  }
  
  .container .title{
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 10px;
    width: 95%;
  }
  
  .content {
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding: 0px;
    margin: auto;
    overflow: hidden;
  }
  
  .content .content-overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    border-radius: 50px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
  }
  
  #myContent {
    padding-left: 0px!important;
  }

  .content:hover .content-overlay{
    opacity: 1;
  }
  
  .content-image{
    width: 100%;
  }
  
  .content-details {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out 0s;
  }
  
  .content:hover .content-details{
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  
  .content-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  
  .content-details p{
    color: #fff;
    font-size: 0.8em;
  }
  
  .fadeIn-bottom{
    top: 80%;
  }
  
  *, *:before, *:after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .img {
    width: 100%;
    border-radius: 30px;
  }
  
  .content {
    border-radius: 20px;
  }

  h2 {
    font-size: 48px;
  }
  
  
  
  .container {
    justify-content: center;
   
  }
.h3-responsive {
    text-align: center;
}

.carousel-control-next-icon {
    padding-bottom: 0px;
}


.view {
    margin-top: 65px;
}

.active {
    height: 400px;
}

img {
    width: 80%;
}
.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
  
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.BillingForm .card-field {
  margin-bottom: 15px;
  background-color: white;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #CCC;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  line-height: 1.3333333;
}

.BillingForm .card-field.StripeElement--focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  border-color: #66AFE9;
}

@media all and (min-width: 480px) {
  .Settings {
    padding: 60px 0;
  }

  .Settings form {
    margin: 0 auto;
    max-width: 480px;
  }
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.Iframe {
    width: 100%;
}

.myClass {
    min-width: 700px;
}
span {
    padding: 20px;
    border-radius: 30px;
    
}
.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    background-color: inherit;
    padding: 10px;
    width: 100%;
    justify-content: space-evenly;
  }
  
  .grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid black;
    text-align: center;
    font-size: 30px;
  }
body {
    padding: 20px;
    margin: 0;
  }

  .header {
    background-color: #001a00;
    border-radius: 20px;
  }
  h1, h2, p, ul, li {
    font-family: sans-serif;
  }

  ul.header2 li {
    display: inline;
    list-style-type: none;
    margin: 0;
    color: white;
  }

  .header2 {
    background-color: #001a00;
    border-radius: 20px;
  }
  h1, h2, p, ul, li {
    font-family: sans-serif;
  }
  ul.header2 li {
    display: inline;
    list-style-type: none;
    margin: 0;
    color: white;
  }

  #myLink:hover {
    color: red;
  }
  
  .MyMenuButton, .MyMenuButton2, .MyMenuButton3 {
    display:inline;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
  }

  .MyMenuButton:hover, .MyMenuButton2:hover, .MyMenuButton3:hover {
    color: lawngreen;
  }

  .MyMenuButton:active {
    background: black;
  }

  .MyMenuButton-menuItem:hover {
    color: lawngreen;
  }

.content {
    padding-left: 25px!important; 
}


form {
    align-items: center;
    z-index: 3;
    margin-top: 200px;
}
body {
    padding: 20px;
    margin: 0;
  }

  .header {
    background-color: #001a00;
    border-radius: 20px;
  }
  h1, h2, p, ul, li {
    font-family: sans-serif;
  }
  ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
    color: white;
  }

  #myLink:hover {
    color: red;
  }

  h4 {
    font-size: 28px;
    color: white;
  }
 
  


body {
    padding: 20px;
    margin: 0;
  }

  .header {
    background-color: lavender;
    border-radius: 20px;
  }
  h1, h2, p, ul, li {
    font-family: sans-serif;
  }
  ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
    color: plum;
  }
  ul.header {
    background-color: lavender;
    padding: 0;
    color: plum;
    
  }
  ul.header li a {
    color: plum;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
    
  }

  


body {
    padding: 20px;
    margin: 0;
  }

  .header {
    background-color: lavender;
    border-radius: 20px;
  }
  h1, h2, p, ul, li {
    font-family: sans-serif;
  }
  ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
    color: plum;
  }
  ul.header {
    background-color: lavender;
    padding: 0;
    color: plum;
    
  }
  ul.header li a {
    color: plum;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
    
  }

  


body {
    padding: 20px;
    margin: 0;
  }

  .header {
    background-color: lavender;
    border-radius: 20px;
  }
  h1, h2, p, ul, li {
    font-family: sans-serif;
  }
  ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
    color: plum;
  }
  ul.header {
    background-color: lavender;
    padding: 0;
    color: plum;
    
  }
  ul.header li a {
    color: plum;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
    
  }

  


body {
    padding: 20px;
    margin: 0;
  }

  .header {
    background-color: lavender;
    border-radius: 20px;
  }
  h1, h2, p, ul, li {
    font-family: sans-serif;
  }
  ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
    color: plum;
  }
  ul.header {
    background-color: lavender;
    padding: 0;
    color: plum;
    
  }
  ul.header li a {
    color: plum;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
    
  }

  


/*
*  Basic Reset
*/
 
* {
	font-size: 18px;
}

*,
*:after,
*:before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/**
 * Basics Styles
 */
html {
	font-size: 62.5%;
}
body {
	background-color: #eee;
	font-family: Roboto;
	font-weight: 400;
	font-size: 1.6em;
	line-height: 1.6;
	color: #666;
}

#myImage {
	width: 50px!important;
}

a {
	text-decoration: none;
	color: #3498db;
}
	a:hover {
		color: #2980b9;
	}

h2 {
	line-height: 1.2;
	margin-bottom: 1.6rem;
}

.wrapper {
	max-width: 1200px;
	margin: 30px auto;
	padding-left: 1em;
    padding-right: 1em;

}

/**
 * Helpers
 */
.border-tlr-radius { 
	border-top-left-radius: 2px;
	border-top-right-radius: 2px; 
}

.text-center { text-align: center; }

.radius { border-radius: 2px; }

.padding-tb { padding-top: 1.6rem; padding-bottom: 1.6rem;}

.shadowDepth0 { box-shadow: 0 1px 3px rgba(0,0,0, 0.12); }

.shadowDepth1 {
   box-shadow: 
  		0 1px 3px rgba(0,0,0, 0.12),
    	0 1px 2px rgba(0,0,0, 0.24);      
}


/**
 * Card Styles
 */

.card {
	background-color: #fff;
	margin-bottom: 1.6rem;
}

.card__padding {
	padding: 1.6rem;
}
 
.card__image {
	min-height: 100px;
	background-color: #eee;
}
	.card__image img {
		width: 100%;
		max-width: 100%;
		display: block;
	}

.card__content {
	position: relative;
}

/* card meta */
.card__meta time {
	font-size: 1.5rem;
	color: #bbb;
	margin-left: 0.8rem;
}

/* card article */
.card__article a {
	text-decoration: none;
	color: #444;
	transition: all 0.5s ease;
}
	.card__article a:hover {
		color: #2980b9;
	}

/* card action */
.card__action {
	overflow: hidden;
	padding-right: 1.6rem;
	padding-left: 1.6rem;
	padding-bottom: 1.6rem;
}
	 

	.card__author img,
	.card__author-content {
		display: inline-block;
		vertical-align: middle;
	}

	.card__author img{
		border-radius: 50%;
		margin-right: 0.6em;
	}

.card__share {
	float: right;
	position: relative;
	margin-top: -42px;
}

.card__social {
	position: absolute;
	top: 0;
	right: 0;
	visibility: hidden;
	width: 160px;
	-webkit-transform: translateZ(0);
	        transform: translateZ(0);
  	-webkit-transform: translateX(0px);
  	        transform: translateX(0px);
  	transition: -webkit-transform 0.35s ease;
  	transition: transform 0.35s ease;
  	transition: transform 0.35s ease, -webkit-transform 0.35s ease;
}
	.card__social--active {
		visibility: visible;
		/*z-index: 3;*/
		-webkit-transform: translateZ(0);
		        transform: translateZ(0);
 		-webkit-transform: translateX(-48px);
 		        transform: translateX(-48px);
  		transition: -webkit-transform 0.35s ease;
  		transition: transform 0.35s ease;
  		transition: transform 0.35s ease, -webkit-transform 0.35s ease;
	}

.share-toggle {
	z-index: 2;
}
.share-toggle:before {
	content: "\f1e0";
	font-family: 'FontAwesome';
	color: #3498db;
}
	.share-toggle.share-expanded:before {
		content: "\f00d";
	}

.share-icon {
	display: inline-block;
	width: 48px;
	height: 48px;
	line-height: 48px;
	text-align: center;
	border-radius: 50%;
	background-color: #fff;
	transition: all 0.3s ease;
	outline: 0;

	box-shadow: 
	  		0 2px 4px rgba(0,0,0, 0.12),
	    	0 2px 4px rgba(0,0,0, 0.24);
}
	.share-icon:hover,
	.share-icon:focus {
		box-shadow: 
	  		0 3px 6px rgba(0,0,0, 0.12),
	    	0 3px 6px rgba(0,0,0, 0.24);

	    -webkit-transform: scale(1.2);
	    transform: scale(1.2);
	}

.facebook {
	background-color: #3b5998; 
}
.twitter {
	background-color: #00abe3; 
}
.googleplus {
	background-color: #d3492c;
}

.facebook,
.twitter,
.googleplus {
	color: #fff;
}

	.facebook:hover,
	.twitter:hover,
	.googleplus:hover {
		color: #eee;
	}

.header {
	background-color: inherit;
}

.border-tlr-radius {
	border-radius: 20px;
}

.shadowDepth1 {
	border-radius: 20px;
}


.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto; 
    grid-gap: 10px;
    background-color: inherit;
    padding: 10px;
  }
  
  .grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid black;
    text-align: center;
    font-size: 30px;
  }
#EventLog b {
    color: #388e3c;
  }
  
  hr {
    margin: 1px 10px 1px 0px;
    border-top: 1px solid #eee;
  }
  
  .e-schedule .e-schedule-toolbar .e-icon-schedule-print::before {
    content: '\e973';
  }
  
  .e-schedule .e-schedule-toolbar .e-icon-schedule-pdf::before {
    content: '\e7c8';
  }
  
  .custom-field-row {
    margin-bottom: 20px;
  }
  
  .multi-prop div {
    padding-left: 0;
    padding-top: 0;
  }

  .e-toolbar-left {
    height: 42px!important;
  }

  .e-toolbar-item .e-prev  {
    height: 42px!important;
  }

  .e-toolbar-item .e-next  {
    height: 42px!important;
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
    height: 42px!important;
  }

  .e-btn-icon .e-icon-next .e-icons {
    height: 25px!important;
  }
  .e-btn-icon .e-icon-prev .e-icons {
    height: 25px!important;
    padding: none!important;
  }

  .span {
    padding: none;
  }

  .e-popup-close {
    padding: none;
  }
* {
    font-size: 24px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 8px;
}

h1 {
    align-items: center;
    align-content: center;
    justify-items: center;
}

.myBox:hover {
    background-color: tan;
}

a:hover {
    color: black;
}

.gridBoxes {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}

h1 .gridBoxes {
    width: 100%;
}
body {
    padding: 20px;
    margin: 0;
  }

  .header {
    background-color: #001a00;
    border-radius: 20px;
  }
  h1, h2, p, ul, li {
    font-family: sans-serif;
  }
  ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
    color: white;
  }

  #myLink:hover {
    color: red;
  }

  h4 {
    font-size: 28px;
    color: white;
  }
 
  


.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
.daabdive {
    text-align: center;
}
h1 {
    text-align: center;
}

.btn {
    margin: 10px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.iJhwIL {
    background-color: lawngreen;
}

.Physics {
    background-color: black;
    background: inherit;
}

.StyledBox-sc-13pk1d4-0 .evKdmY:hover {
    background-color: plum;
}

.gridSystem {
    color: black;
}


body {
    background-color:#354e01 ;
}

.StyledGrommet-sc-19lkkz7-0.gVQrIe {
    background: inherit;
}

.StyledHeading-sc-1rdh4aw-0 .blIzXS .myHeader {
    margin-top: none !important;
}

.myFooter {
  margin-top: 300px;
}

.physHeader {
    margin-top: 55px;
    padding-left: 25px;
    margin: 10px;
    margin-right: 10px;
    vertical-align: middle;
    font-size: 1.6em;
    font-weight: normal;
    
    background-color:#354e01;
}

.MuiPaper-root .MuiCard-root .physHeader .MuiPaper-elevation1 .MuiPaper-rounded {
    border-width: 3px;
}
ul {
    list-style-type: none;
}

li {
    padding-left: 20px;
}

.mainClass {
    margin-top: 70px;
}
.HeaderClass {
    border: 5px solid;
    border-color: black;
    color: white;
    background-color: inherit;
    border-radius: 8px;
}
.div .myFooter {
    border: #001a00;
    border-radius: 20px;
    border-width: 1px;
}


.App {
  width: 100%;
  background: #001a00;
}

.myCarousel {
  text-align: center;
  width: 100%;
}

.App .navbar-brand {
  font-weight: bold;
  padding: 3px;
}

.container {
  width: 100%;
}

.navbar {
  background-color:#001a00;
  border-radius: 20px;
  position: fixed;
  width: 94.5%;
  margin-top: none;
  z-index: 2;
  margin-bottom: 10px;
}

body {
  padding: 0;
}

h1 {
  margin: none;
}

.myFooter {
  position: relative;
  height: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.Physics {
  margin-top: 35px;
}

.header {
  margin-top: 70px;
  margin-bottom: 20px;
}

@media (max-width: 760px) {
  #myNavHeader {
    display: none;
  }
}
