.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    background-color: inherit;
    padding: 10px;
    width: 100%;
    justify-content: space-evenly;
  }
  
  .grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid black;
    text-align: center;
    font-size: 30px;
  }