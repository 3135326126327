* {
    font-size: 24px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 8px;
}

h1 {
    align-items: center;
    align-content: center;
    justify-items: center;
}

.myBox:hover {
    background-color: tan;
}

a:hover {
    color: black;
}

.gridBoxes {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}

h1 .gridBoxes {
    width: 100%;
}